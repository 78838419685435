import React from 'react';

import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
           <div>Last Updated: 4/25/19</div>
        </div>
    );
};
export default Footer;